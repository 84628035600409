<template>
  <div>
    <el-row class="dash-board">
      <el-col style="width: 565px;">
        <p style="font-size: 13px; position: absolute; top: 10px; left: 17px">日期：</p>
        <el-date-picker v-model="datetime" type="datetimerange" range-separator="至" value-format="yyyy-MM-dd HH:mm:ss"
          start-placeholder="开始日期" end-placeholder="结束日期" style="position: absolute; top: 15px; left: 60px; width: 250px">
        </el-date-picker>
        <p style="font-size: 13px; position: absolute; top:10px; left: 330px ">状态：</p>
        <el-select style="width: 160px; position: absolute; top: 15px;left: 370px" v-model="stes">
          <el-option v-for="(i,k) in dictionaryList3" :key="k" :value="i.sort" :label="i.value"></el-option>
        </el-select>
      </el-col>
      <el-col style="width: 210px;display: flex;align-items: center;">
        <el-button type="primary" @click="select">查询</el-button>
        <el-button type="warning" @click="reset">重置</el-button>
        <el-button type="success" @click="add" >+申请</el-button>
      </el-col>
    </el-row>
    <el-dialog :title="title" :visible.sync="reapplyfor" class="log">
      <el-form>
        <div class="content">
          <el-row :gutter="100">
            <el-form :model="leaves" label-position="right" label-width="120px" :rules="rules2" ref="leaves">
              <el-col :span="11">
                <el-form-item label="选择请假的班级" prop="classId">
                  <el-select style="width: 400px" v-model="leaves.classId" @change="classChange">
                    <el-option v-for="(i,k) in dictionaryList5" :key="k" :label="i.className" :value="i.classId">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="类别" prop="leaveType">
                  <el-select style="width: 400px" v-model="leaves.leaveType">
                    <el-option v-for="(i) in dictionaryList1" :key="i.id" :value="i.id" :label="i.value" />
                  </el-select>
                </el-form-item>
                <el-form-item label="请假课程" prop="courseId" >
                    <el-select style="width: 400px" v-model="leaves.courseId" multiple @change="selectChange">
                      <el-option v-for="i in courseList" :key="i.courseId" :value="i.courseId" :label="i.content" />
                    </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="请假事由" prop="leaveReason">
                  <el-input type="textarea" rows="4" v-model="leaves.leaveReason"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-upload
                  class="upload-demo"
                  :action="uploadImgUrl"
                  :on-preview="handlePreview"
                  :on-remove="handleRemove"
                  :headers="headers"
                  max-count="1"
                  :file-list="fileList"
                  :on-change="onChange"
                  list-type="picture">
                  <el-button size="small" type="primary">点击上传</el-button>
                  <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div>
                </el-upload>
              </el-col>
            </el-form>
          </el-row>
          <div class="dialog-footer">
            <el-button @click="reapplyfor = false">取 消</el-button>
            <el-button type="primary" @click="LeaAdd('leaves')">提交</el-button>
          </div>
        </div>
      </el-form>
    </el-dialog>
    <el-row class="bg">
      <el-table :data="List5" style="width: 100%;">
        <el-table-column label="申请人" align="center" width="80">
          <template slot-scope="scope">
            <span>{{ scope.row.stuName }}</span>
          </template>
        </el-table-column>
        <el-table-column label="班级" align="center" width="220">
          <template slot-scope="scope">
            <span>{{ scope.row.className }}</span>
          </template>
        </el-table-column>
        <el-table-column label="课程内容" align="center" width="220">
          <template slot-scope="scope">
            <span>{{ scope.row.content }}</span>
          </template>
        </el-table-column>
        <el-table-column label="类别" align="center" width="100">
          <template slot-scope="scope">
            <el-tag type="primary" v-if="scope.row.leaveType==2">病假</el-tag>
            <el-tag type="primary" v-if="scope.row.leaveType==5">事假</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="请假事由" align="center" width="200">
          <template slot-scope="scope">
            <span>{{scope.row.leaveReason}}</span>
          </template>
        </el-table-column>
        <el-table-column label="请假图片" align="center" width="200">
          <template slot-scope="scope">
          <span class="spani" v-if="scope.row.url">
            <el-popover placement="right" trigger="click">
              <img :src="scope.row.url" height="400">
              <img slot="reference" :src="scope.row.url" height="50" width="50">
            </el-popover>
          </span>
        </template>
        </el-table-column>
        <el-table-column label="提交时间" align="center" width="180">
          <template slot-scope="scope">
            <span>{{scope.row.createTime}}</span>
          </template>
        </el-table-column>
        <el-table-column label="状态" align="center" width="140">
          <template slot-scope="scope">
            <el-tag type="primary" v-if="scope.row.status == 12">班主任审批中</el-tag>
            <el-tag type="primary" v-if="scope.row.status == 11">班主任审批中</el-tag>
            <el-tag type="primary" v-if="scope.row.status == 21">组织部审批中</el-tag>
            <el-tag type="primary" v-if="scope.row.status == 22">组织部审批中</el-tag>
            <el-tag type="success" v-if="scope.row.status == 3">审批成功</el-tag>
            <el-tag type="danger" v-if="scope.row.status == 4">审批失败</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button type="danger" size="mini" @click="delLeave(scope.row)">删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
     <el-row style="margin-top:20px">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pageNum"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          class="pag">
      </el-pagination>
    </el-row>
  </div>
</template>
<script>
  import {
    mapMutations
  } from 'vuex'
  import {
    LeaAdd,
    GetAllById,
    getClassNameByLogin,
    getLeave,
    delLeave,
    getLeaveList,
    getCourses
  } from '@/api/leave.js'
  import { getUrl } from '@/utils/request.js'
  import { getCookie } from '@/utils/base'
  export default {
    name: 'Leavefer',
    data() {
      return {
        uploadImgUrl: getUrl() + '/upload/admin', // 图片上传地址
        headers: { // token
          Authorization: getCookie('Authorization')
        },
        // 上传文件
        fileList: [],
        // 上传成功
        list: [{ name: '', url: '' }],
        // 上传按钮禁用
        uploadDisabled: false,
        id: '',
        leaveId: '',
        List5: [],
        courseList: [],
        datetime: '',
        stes: '',
        userNames: '',
        types: '',
        dictionaryList0: [],
        dictionaryList1: [{
          id: '2',
          value: '病假'
        }, {
          id: '5',
          value: '事假'
        }],
        dictionaryList2: [],
        dictionaryList3: [],
        dictionaryList5: [{
          id: '',
          classname: ''
        }],
        leaves: { // 请假申请
          usersname: '',
          classId: [],
          leaveType: undefined,
          CreateTime: '',
          days: '',
          Type: '',
          leaveReason: '',
          approver: '',
          stuId: ''
        },
        title: '填写请假信息',
        reapplyfor: false,
        query: {},
        total: 0,
        pageNum: 1,
        pageSize: 10,
        rules2: {
          classId: [{
            required: true,
            message: '请选择班级',
            trigger: 'change'
          }],
          days: [{
            required: true,
            message: '请填写天数',
            trigger: 'blur'
          }],
          leaveType: [{
            required: true,
            message: '请选择类别',
            trigger: 'change'
          }],
          courseId: [{ type: 'array', required: true, message: '请至少选择一个课程', trigger: 'change' }],
          leaveReason: [{
            required: true,
            message: '请填写事由',
            trigger: 'blur'
          }]
        }
      }
    },
    mounted() {
      this.GetAllById(184) // 病假类型
      this.GetAllById(180) // 根据状态查询
      this.getClassNameByLogin(this.$store.getters.username)
      this.select() // 初始化个人请假信息
    },
    methods: {
      ...mapMutations({
        saveUser: 'SAVE_USER'
      }),
      handleRemove(file, fileList) {
        console.log(file, fileList)
        this.list = []
      },
      handlePreview(file) {
        console.log(file)
      },
      // 文件上传发生改变
      onChange(res) {
        if (res.response) {
          if (res.response.code === '200') {
              this.$notify({
                title: '成功',
                message: '上传成功',
                type: 'success'
              })
              this.list[0].url = res.response.data.url
          }
        }
      },
      add() {
        this.leaves = {}
        this.reapplyfor = true
        // this.$refs.leaves.clearValidate()
      },
      classChange(e) {
        console.log(e)
        const query = {
          classId: e
        }
        getCourses(query).then(res => {
          this.courseList = res.data.data
        })
      },
      selectChange(e) {
        console.log(e)
      },
      UpdataLeave(row) {
        this.reapplyfor = true
        const ModifyItem = row.leaveId
        const username = this.$store.getters.username
        getLeave(ModifyItem, username).then(resp => {
          if (resp.data.code === '0') {
            this.leaves = resp.data.data
            this.$set(this.leaves, 'Type', this.leaves.leaveType)
            const that = this
            this.$set(that.leaves, 'CreateTime', ['' + that.leaves.createTime, '' + that.leaves.endTime])
          }
        })
      },
      delLeave(row) {
        this.$confirm('是否删除?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then((e) => {
          if (e === 'confirm') {
            delLeave(row.leaveId).then(res => {
              if (res.data.code === '0') {
                this.$notify({
                  title: '删除成功',
                  type: 'success'
                })
                this.select()
                // const index = this.dataList.indexOf(row)
                // this.dataList.splice(index, 1)
                // this.total--
              }
            })
          }
        }).catch(() => {})
      },
      // 请假申请
      LeaAdd(leaves) {
        if (this.leaves.Type === '病假') {
          this.types = 2
        } else if (this.leaves.Type === '事假') {
          this.types = 5
        }
        this.$refs[leaves].validate((valid) => {
          if (valid) {
            const Leave = {
              id: this.leaves.id,
              stuId: this.$store.getters.username,
              classId: this.leaves.classId,
              days: this.leaves.days,
              courseIds: this.leaves.courseId,
              leaveType: this.leaves.leaveType,
              leaveReason: this.leaves.leaveReason,
              approver: this.leaves.approver,
              files: this.list
            }
            console.log(leaves)
            LeaAdd(Leave).then(resp => {
              if (resp.data.code === '200') {
                this.$notify({
                  title: '成功',
                  message: '申请请假成功',
                  type: 'success'
                })
                this.reapplyfor = false
                this.select()
                this[leaves] = {}
              } else if (resp.data.code === '201') {
                this.$notify({
                  title: '成功',
                  message: '修改成功',
                  type: 'success'
                })
                this.reapplyfor = false
                this.select()
                this[leaves] = {}
              }
            })
          }
        })
      },
      GetAllById(id) {
        GetAllById(id).then(resp => {
          if (resp.data.code === '0') {
            for (let i = 0; i < 3; i++) {
              if (id === 184) {
                // this.dictionaryList1 = resp.data.data // 赋值
                console.log(this.dictionaryList1)
              } else if (id === 180) {
                this.dictionaryList3 = resp.data.data
                console.log(this.dictionaryList3)
              }
            }
          }
        })
      },
      getClassNameByLogin(userNames) {
        getClassNameByLogin(userNames).then(resp => {
          if (resp.data.code === '0') {
            this.dictionaryList5 = resp.data.data
          }
        })
      },
      reset() {
        this.datetime = ''
        this.stes = ''
      },
      select() {
        const query = {
          startTime: this.datetime[0],
          endTime: this.datetime[1],
          leaveStatus: this.stes,
          username: this.$store.getters.username,
          pageNum: this.pageNum,
          pageSize: this.pageSize
        }
        getLeaveList(query).then(resp => {
          if (resp.data.code === '200') {
            this.List5 = resp.data.data.rows
            this.reapplyfor = false
            this.total = resp.data.data.total // 查询总条数
          }
        })
      },
      // 分页
      handleSizeChange(e) {
        this.pageSize = e
        this.select()
      },
      handleCurrentChange(e) {
        this.pageNum = e
        this.select()
      }
    }
  }
</script>

<style scoped>
  .bg {
    margin-top: 15px;
    padding: 20px;
    background-color: white;
  }

  .dash-board {
    display: flex;
    /*left: 10px;*/
    background-color: white;
    height: 60px;
    position: relative;
  }

  .inquire {
    position: absolute;
    top: 15px;
    left: 60%;
  }

  .reset {
    position: absolute;
    top: 15px;
    left: 67%;
  }

  .applyfor {
    position: absolute;
    top: 15px;
    left: 90%;
  }

  .dialog-footer {
    position: relative;
    left: 20px;
  }
</style>
